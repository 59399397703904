import { css } from "@emotion/react"
import * as React from "react"
import Body from "../components/Body"
import CompanyNaverMap from "../components/CompanyNaverMap"
import GateImage from "../components/GateImage"
import NavBar from "../components/NavBar"
import SectionTitle from "../components/SectionTitle"
import ToTopButton from "../components/ToTopButton"
import Games from "../components/Games"


const IndexPage = () => {
  const gameRef = React.useRef();
  const contactUsRef = React.useRef();
  console.log('index page')
  return (
    <main css={mainWrapper}>
      <NavBar onMenuClick={(menu) => {
        if (menu === "Games") {
          window.scrollTo({
            left: 0,
            top: gameRef.current.offsetTop,
            behavior: 'smooth'
          })
        }

        if (menu === "ContactUs") {
          window.scrollTo({
            left: 0,
            top: contactUsRef.current.offsetTop,
            behavior: 'smooth'
          })
        }
      }} />
      <GateImage />
      <Body>
        <section css={logoWrapper}>
          <img src="/logo-supermagic.png" alt="supermagic-logo" css={logoImage} />
        </section>
        <section css={helloWrapper}>
          <span css={pcTitle}>HELLO!!</span>
          <span css={pcTitle}>WE WILL STEAL YOUR TIME.</span>
          <span css={mobileTitle}>HELLO!! WE WILL STEAL YOUR TIME.</span>
        </section>
        <section css={helloDescriptionWrapper}>
          <span>
            SuperMagic is a group of simple games. 
          </span>
          <span>
            If you want SuperMagic to publish your precious game, contact us.
          </span>
        </section>
        <section ref={gameRef}>
          <SectionTitle title="games" />
          <Games />
        </section>

        <section css={contactWrapper} ref={contactUsRef}>
          <SectionTitle title="contactus" />
          <CompanyNaverMap />
          <section css={contactDescriptionWrapper}>
            <article>
              <img css={mapImg} src="/img-map.jpg" alt="address" />
            </article>
            <a href={`https://map.naver.com/v5/search/gfc/place/13226809?c=14141178.7426627,4509031.6877385,15,0,0,0,dh&placePath=%3Fentry%253Dbmp`}>
              <span>06236 152, Teheran-ro, Gangnam-gu, Seoul, Republic of Korea GFC(Gangnam Finance Center), 33F</span>
            </a>
          </section>
          <section css={contactDescriptionWrapper}>
            <article>
              <img css={mailImg} src="/img-mail.jpg" alt="mail" />
            </article>
            <a href="mailto:help@supermagic.io">
              <span>help@supermagic.io</span>
            </a>
          </section>
        </section>
      </Body>
      <footer css={footerWrapper}>
        <section css={footerContents}>
          <section>
            <div css={personalPlicy}>
              <a href="/policy">Privacy Policy</a>
            </div>
            <div css={copyright}>
              © 2023. Supermagic Inc. All rights reserved. 
            </div>
          </section>
          <ToTopButton />
        </section>
      </footer>
    </main>
  )
}

const mainWrapper = css`
  
`

const logoWrapper = css`
  text-align: center;
  margin-top: 380px;
  @media screen and (max-width: 768px) {
    margin-top: 100px;
  }
`

const logoImage = css`
  width: 33%;
  height: auto;
  max-width: 457px;

  @media screen and (max-width: 768px) {
    width: 60%;
    max-width: 411px;
  }
`

const helloWrapper = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 62px;
  > span {
    font-size: 64px;
    font-weight: 900;
    color: #e40606;
    line-height: 1;
    font-family: "Exo2";
    text-align: center;
  }

  @media screen and (max-width: 768px) {
    flex-direction: row;
    justify-content: center;
    > span {
      font-size: 67px;
    }
  }
  @media screen and (max-width: 450px) {
    margin-top: 30px;
    > span {
      font-size: 34px;
    }
  }
`

const helloDescriptionWrapper = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 58px;
  margin-bottom: 400px;
  flex-wrap: wrap;
  > span {
    font-size: 26px;
    line-height: 1.5;
    color: #363442;
    font-family: "NotoSans";
    word-break: break-word;
  }

  @media screen and (max-width: 768px) {
    > span {
      text-align: center;
      font-size: 24px;
    }
  }

  @media screen and (max-width: 450px) {
    > span {
      font-size: 16px;
    }
    margin-bottom: 200px;
    padding: 0 10%;
  }
`

const contactWrapper = css`
  padding: 0 140px;
  margin-bottom: 150px;
  margin-top: 350px;
  @media screen and (max-width: 768px) {
    padding: 0 4%;
  }

  @media screen and (max-width: 450px) {
    margin-bottom: 48px;
    margin-top: 202px;
  }
`

const contactDescriptionWrapper = css`
  display: flex;
  align-items: center;
  margin-top: 26px;

  a {
    text-decoration: none;
    width: 90%;
  }

  article {
    display: flex;
    align-items: center;
    width: 5%;
    
  }

  span {
    font-size: 20px;
    line-height: 1.2;
    color: #363442;
    opacity: 0.8;
    
    font-family: "NotoSans";
    &:hover {
      color: #e40606;
    }
  }

  @media screen and (max-width: 768px) {
    article {
      width: 8%;
    }

    span {
      width: 92%;
    }
  }

  @media screen and (max-width: 450px) {
    span {
      font-size: 12px;
    }
  }
`

const mailImg = css`
  width: 30px;
  height: auto;

  @media screen and (max-width: 768px) {
    width: 70%;
  }
`

const mapImg = css`
  width: 36px;
  height: auto;
  @media screen and (max-width: 768px) {
    width: 80%;
  }
`

export default IndexPage


const pcTitle = css`
  @media screen and (max-width: 768px) {
    display: none;
  }
`

const mobileTitle = css`
  display: none;
  @media screen and (max-width: 768px) {
    display: inline-block;
    text-align: center;
  }
`

const footerWrapper = css`
  width: 100%;
  display: flex;
  justify-content: center;
  background: #e8e8e8;

  @media screen and (max-width: 450px) {
    padding-bottom: 0px;
  }
`

const footerContents = css`
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  width: 1400px;
  padding: 24px 140px 0;
  @media screen and (max-width: 1400px) {
    width: 100%;
    padding: 16px 0 0 10px;
  }
`

const personalPlicy = css`
  font-family: "NotoSans";
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;

  a {
    text-decoration: none;
    color: #2c2c2c;
  }

  @media screen and (max-width: 450px) {
    font-size: 16px;
  }

  &:hover {
    a {
      color: #e40606;
    }
  }
`

const copyright = css`
  margin-top: 10px;
  margin-bottom: 42px;
  font-family: "NotoSans";
  font-size: 18px;
  font-weight: 300;

  @media screen and (max-width: 450px) {
    font-size: 12px;
    margin-top: 4px;
  }
`